#root {
    overflow: hidden;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
    fill: currentColor;
    color: #dddddd !important;
}

.App-header, .App-about, .App-contact, .App-subscribe {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-about, .App-contact, .App-subscribe {
    border-top: 1px solid #777;
}

.App-footer {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: auto;
}
